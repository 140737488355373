import React from 'react';
import styled from 'styled-components';
import { FiFacebook } from 'react-icons/fi';

const FooterContainer = styled.footer`
  background-color: var(--highlight-color);
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footerLinks > p {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 0.75em;
    margin: 0;
    padding: 0;
    margin-top: 0.75em;
  }

  .socialLinks > a {
    color: var(--text-color);
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .footerLinks > p {
      text-align: left;
      font-size: 0.5em;
    }
  }
`;

const Footer = () => (
  <FooterContainer>
    <div className="footerLinks">
      <p>&copy; 2020 Pristine Polish</p>
    </div>
    <div className="socialLinks">
      <a
        href="https://www.facebook.com/PristinePolishGlasgow"
        target="_blank"
        rel="noopener noreferrer"
        name="facebook page link"
      >
        <FiFacebook size="1em" />
      </a>
    </div>

    <div className="footerLinks">
      <p>louise@pristinepolish.co.uk</p>
    </div>
  </FooterContainer>
);

export default Footer;

const config = {
  title: `Pristine Polish`,
  titleShort: `pristinepolish.co.uk`,
  titleAlt: `Pristine Polish - Glasgow Cleaning Service`,
  logo: `/icons/logo-512.png`,
  url: `https://www.pristinepolish.co.uk/`,
  pathPrefix: `/`,
  description: `A Glasgow based cleaning service delivering a high-quality service to brighten up and freshen your home or business.`,
  lang: `en`,
  themeColor: `#9c27b0`,
  backgroundColor: `#673ab7`,
  image: `/assets/images/Header.jpg`,
  titleTemplate: `Pristine Polish - %s`,
  locale: `en_GB`,
};

// Validate
// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === `/`) {
  config.pathPrefix = ``;
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.url.substr(-1) === `/`) config.url = config.url.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== `/`)
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
